import { Auth } from 'aws-amplify';

const awsmobile = {
  Auth: {
    // Amazon Cognito Region
    region: "eu-west-1",

    // Amazon Cognito User Pool ID
    userPoolId: "eu-west-1_XiXctd0Ro",

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "2ijmhd8644tjvmajnqgk96oh7m"
  },
  API: {
    endpoints: [
        {
            name: "Amazon Get Orders",
            endpoint: "https://l4933dm05d.execute-api.eu-west-1.amazonaws.com",
            custom_header: async() => {
                return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
        }
    ]
  }

};
export default awsmobile;