import React, {Component} from 'react';
//import logo from './logo.svg';
import './App.css';
import Materialize from "materialize-css";
import Amplify from 'aws-amplify';
import { API, Auth } from 'aws-amplify';
import awsmobile from './aws-exports';
import {Greetings, SignIn, ConfirmSignIn, VerifyContact, ForgotPassword, RequireNewPassword, withAuthenticator} from 'aws-amplify-react';

Amplify.configure(awsmobile);


/*class MyGreetings extends Greetings {
    userGreetings(theme) {
		const user = this.props.authData || this.state.authData;
		const greeting = this.props.inGreeting || this.inGreeting;
		// get name from attributes first
		const { usernameAttributes = 'username' } = this.props;
		let name = '';
        name = user.attributes ? user.attributes.email : user.username;
		
        const message = typeof greeting === 'function' ? greeting(name) : greeting;

		return (
			<span>
				<NavItem theme={theme}>{message}</NavItem>
				{this.renderSignOutButton()}
			</span>
		);

    }
}*/

    class App extends Component {
      constructor(props) {
        super(props);
        this.state = {
          orders: [],
          items: [],
          shipment: {},
          service: 1
        };
      this.changePage = this.changePage.bind(this);
      }
      componentDidMount() {
        let apiName = "Amazon Get Orders";
        let path = "/prod/orders";
        /* fetch('https://l4933dm05d.execute-api.eu-west-1.amazonaws.com/prod/orders') */
        
        API.get(apiName, path)
       // .then(res => res.json())
        .then((data) => {
          //this.setState({ orders: JSON.parse(data.body) })
          this.setState({ orders: data })
          this.setState({ orders: this.state.orders.map((d, i) =>
                                                        {
                                                            d.id = d.po + '_' + d.ean;
                                                            d.open_quantity = 0;
                                                            if (d.hasOwnProperty('shipped_quantity') === true) {
                                                                d.open_quantity = d.order_quantity - d.shipped_quantity;
                                                            } else {
                                                                d.open_quantity = d.order_quantity;
                                                            }
                                                         //   d.checked = false;
                                                            return d} ) })
          console.log("I went fishing.")                    
        })
        .catch(console.log)
     
         
      } 

      changePage(e) {
        //console.log("Page change request: ", e);
        this.setState({service: e.newpage});
        if ((typeof e.markeditems !== 'undefined') && (e.newpage === 2)) {
            //console.log("I found something!")
            this.setState({ orders: this.state.orders.map((d) =>
                                                          {
                                                            if (e.markeditems.includes(d.id)) {
                                                                d.marked = true
                                                            } else {
                                                                d.marked = false
                                                            }
                                                            return d
                                                          }
                                                          
                                                         )});
        } else if (typeof(e.palletcount) !== "undefined") {
            this.setState({ orders: this.state.orders.map((d) =>
                                                          {
                                                            e.palletcount.forEach((x) =>
                                                                                  {
                                                                                    if (x.id === d.id) {
                                                                                        d.pallets = x.pallets
                                                                                    }
                                                                                  });
                                                          return d
                                                          }
                                                          
                                                          )});
        } else if (typeof(e.shipment) !== "undefined") {
            this.setState({ items: e.shipment.map((d) =>
                                                     {
                                                        return d
                                                     })});
        } else if (typeof(e.shiprec_date) !== "undefined") {
            this.setState({ shipment: {
                                    vendor: e.vendor,
                                    destination: e.destination,
                                    shipout_date: e.shipout_date,
                                    shiprec_date: e.shiprec_date
                                    }}); 
        } else { console.log("There is nothing") };
        //return 2;
      }
      
      render() {      
         let pagemodule = "";
         const page = this.state.service;
         if (page === 1) {
                pagemodule = <ItemSelector orders={this.state.orders} onPageChange={this.changePage} />
         } else if (page === 2) {
                pagemodule = <PalletSelector orders={this.state.orders} onPageChange={this.changePage} />
         } else if (page === 3) {
                pagemodule = <QuantitySelector orders={this.state.orders} onPageChange={this.changePage} />
         } else if (page === 4) {
                pagemodule = <DateSelector orders={this.state.orders} items={this.state.items} onPageChange={this.changePage} />
         } else if (page === 5) {
                pagemodule = <SubmissionPage items={this.state.items} shipment={this.state.shipment} usermail={this.state.usermail} onPageChange={this.changePage} />
         }
        
        
        return (
             <div>
            {pagemodule}
             </div>
        );
      }
    }
 
 
 /* #### Funktion f�r die Auswahl der ASINs zur Lieferung #### */   
    class ItemSelector extends Component {
      constructor(props) {
        super(props);
        this.state = {
        track: []
        };    
        this.handleChange = this.handleChange.bind(this);
        this.handlePage = this.handlePage.bind(this);
      }
      
      componentDidMount() {
        var elems = document.querySelectorAll('.tooltipped');
        Materialize.Tooltip.init(elems);
      }
      
      
      handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var change = {};
        //console.log("Target: ", target, " Value: ", value, " Name: ", name);                 
        //console.log("Track: ", this.state.track)
        
        let mode = "";
        if (this.props.orders.length === this.state.track.length) {
            mode = "u";
            //console.log("Mode: update")
        } else {
            mode = "n";
            //console.log("Mode: new")
        }
        
        let x = this.state.track;
        change.type = "checked";
        change.value = value;
        //get destination of changed line
        this.props.orders.forEach((d) =>
                                      {
                                        if (d.id === name) {
                                            change.dest = d.destination
                                        }
                                      });
        //console.log("Requested change: ", change);
        
        //write new array based on change info
        this.props.orders.forEach((d, i) =>
                                  {
                                    //console.log("D:", d, "I: ", i);
                                    let temp = {};
                                    if (mode === "n") {
                                        temp.id = d.id;
                                        if (d.id === name) {
                                            temp.checked = value
                                        } else {
                                            temp.checked = false
                                        }
                                        if (d.destination === change.dest) {
                                            temp.disabled = false
                                        } else {
                                            temp.disabled = true
                                        }
                                        
                                    } else if (mode === "u") {
                                        temp.id = x[i].id;
                                        if ((d.destination !== change.dest) && (value === true)) {
                                            temp.disabled = true
                                        } else {
                                            temp.disabled = x[i].disabled;
                                        }
                                        if (d.id === name) {
                                            temp.checked = value
                                        } else {
                                            temp.checked = x[i].checked
                                        }                                         
                                    }
                                    x[i] = temp
                                  });
        // check if last box was unchecked - if yes, disable
        let anyset = false;
        let temp = [];
        x.forEach((d) => {
            if (d.checked === true) { anyset = true }
        });
        if (anyset === false) {
            temp = x.map(d => {
                d.disabled = false;
                return d
            });    
        x = temp;
        }
        
        //console.log("Changing: ", x);
        this.setState({track: x});
      }
        
      getBoxProperty(id, type) {
        let reply=false;
        this.state.track.forEach((d) =>
                        { if (d.id === id) {
                           if ((type === "checked" && d.checked === true) || (type === "disabled" && d.disabled === true)) { reply=true } 
                        }
                    });
        return reply;
      }
      
      handleClick(event) {
        console.log("Boo!")
      }

      
      getButtonClass(props) {
        var classname = "waves-effect waves-light btn-large disabled";
        this.state.track.forEach((d) => {
                if (d.checked === true) { classname = "waves-effect waves-light btn-large" }
            });
        return classname
      }
      
      handlePage(e) {
         let handover = {};
         handover.newpage = 2;
         handover.markeditems = [];
         this.state.track.forEach((d) =>
                                  {
                                    if (d.checked === true) {
                                        handover.markeditems.push(d.id)
                                    }
                                  });
         //console.log("Handover: ", handover);
         this.props.onPageChange(handover);  
      }
        
        render() {
            var tablecontent = this.props.orders.map( (d, i) =>
            <tr key={i}><td><label><input type="checkbox" name={d.id} checked={this.getBoxProperty(d.id, "checked")} disabled={this.getBoxProperty(d.id, "disabled")} onChange={this.handleChange} /><span></span></label></td><td>{d.po}</td><td>{d.ean}</td><td>{d.item_name}</td><td>{d.open_quantity}</td><td>{d.destination}</td><td>{d.order_date}</td><td>{d.latest_delivery}</td></tr>
            );
            
            return(
            <div className="container">
            <h4>Neue Lieferung anlegen</h4>
            Bitte w&auml;hlen Sie alle Bestellungen/Artikel aus, die in dieser <span className="blue-text text-darken-2 tooltipped" data-position="right" data-tooltip="Eine Lieferung beinhaltet beliebig viele Artikel, die<br/><br/>a) zum selben Termin,<br/>b) im gleichen Fahrzeug,<br/>c) an das gleiche Ziel<br/><br/>versendet werden.">Lieferung</span> enthalten sein werden.<br /><p />
            
            <form action="#">
             <table className="striped" width="600">
                <thead>
                  <tr>
                    <th></th>
                    <th>PO #</th>
                    <th>ISBN</th>
                    <th>Titel</th>
                    <th>Menge (offen)</th>
                    <th>Lieferziel</th>
                    <th>Bestelldatum</th>
                    <th>Sp&auml;teste Lieferung</th>
                  </tr>
                </thead>
                <tbody>
                  {tablecontent}
                </tbody>
                
             </table>
             </form>
             <p />
             <center>
             <a className={this.getButtonClass()} href="#!" onClick={this.handlePage}>Lieferung vorbereiten</a>
             </center>
             </div>
            )
        }
        
    }
    
 /* #### Funktion f�r die Eingabe der Palettenanzahl #### */
    class PalletSelector extends Component {
      constructor(props) {
        super(props);
        this.handlePage = this.handlePage.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleNr = this.handleNr.bind(this);
        this.state = {
        track: []
        }; 
      }
      
      componentDidMount() {
        let x = [];
        this.props.orders.forEach((d) => {
            let temp = {};
            if (d.marked === true) {
                temp.id = d.id;
                temp.pallets = 1;
                x.push(temp)
            }
        });
        this.setState({track: x});
      }
      
      handlePage(e) {
         let handover = {};
         handover.palletcount = [];
         if (e.target.id === "prev") {
            handover.newpage = 1;
         } else if (e.target.id === "next") {
            handover.newpage = 3; 
         }
         this.state.track.forEach((d) =>
                                  {
                                    let pallet = {};
                                    if ( (Number.isInteger(d.pallets) === true) && (d.pallets > 0) && (d.pallets < 100) ) {
                                        pallet.id = d.id;
                                        pallet.pallets = d.pallets;
                                        handover.palletcount.push(pallet);
                                    }
                                    });
         
         this.props.onPageChange(handover);  
      }
      
      handleNr(id, e) {
        //console.log(typeof(e.target.value));
        this.setState({ track: this.state.track.map((d) =>
                                                   {
                                                    if (d.id === id) {
                                                        if ( ((e.target.value >= 0) && (e.target.value <= 99)) || (e.target.value === "")) {                                                            
                                                          if (e.target.value === "") {
                                                            d.pallets = "";
                                                          } else {
                                                            d.pallets = Number(e.target.value);
                                                          }
                                                        }
                                                    }
                                                   return d
                                                   }
                                                   
                                                   )});
      }
      
      handleButton(x, e) {
          this.setState({ track: this.state.track.map((d) =>
                                                      {
                                                        if (d.id === x.id) {
                                                            if ((x.type === "inc") && (d.pallets < 99)) {
                                                                d.pallets++
                                                            } else if ((x.type === "dec") && (d.pallets > 1)) {
                                                                d.pallets--
                                                            }
                                                        }
                                                      return d
                                                      }                 
                                                      )});
      }
      
      getPallets(e) {
        let pcount = 0;
        this.state.track.forEach((d) => {
            
            if (d.id === e) {
                pcount = d.pallets;
            }
        });
        return pcount;
      }
      
      
      getButtonClass(props) {
        let error = false;
        var classname = "";
        this.state.track.forEach((d) => {
                if ((Number.isInteger(d.pallets) === false) || (d.pallets < 1) || (d.pallets > 99) || (d.pallets === ""))  { error = true }
            });
        if (error === true) {
            classname = "waves-effect waves-light btn-large disabled";
        } else {
            classname = "waves-effect waves-light btn-large";
        }
        return classname
      }
      
      
      render() {      
            
            var tablecontent = this.props.orders.map((d, i) =>
                                                                     {  let ret;
                                                                        if (d.marked === true) { 
                                                                           ret=<tr key={d.id}>
                                                                           <td>{d.po}</td>
                                                                           <td>{d.ean}</td>
                                                                           <td>{d.item_name}</td>
                                                                           <td>
                                                                           <div className="input-group">
                                                                           <input type="button" value="-" className="qtyminus" data-field="quantity" onClick={(e) => this.handleButton({id: d.id, type: "dec"}, e)} />
                                                                           <input type="text" step="1" max="99" maxLength="2" onChange={(e) => this.handleNr(d.id, e)} value={this.getPallets(d.id)} name={d.id} className="qty" />
                                                                           <input type="button" value="+" className="qtyplus" data-field="quantity" onClick={(e) => this.handleButton({id: d.id, type: "inc"}, e)} />
                                                                           </div>
                                                                           </td>
                                                                           </tr>;
                                                                          
                                                                     } 
                                                                     return ret   
                                                                     }                                                                                
                                                     ); 
            var divStyle = { padding: "0 28px",
                            height: "54px",
                            lineHeight: "54px",
                            verticalAlign: "middle"};
            return(
               <div className="container">
               <h4>Anzahl der Paletten erfassen</h4>
               Bitte geben Sie f&uuml;r jeden Artikel die Anzahl der Paletten ein, auf denen der Artikel geliefert wird.<p />
                 <form action="#">
                   <table className="striped">
                   <thead>
                      <tr>
                        <th>PO #</th>
                        <th>ISBN</th>
                        <th>Titel</th>
                        <th>Anzahl Paletten</th>
                     </tr>
                   </thead>
                   <tbody>{tablecontent}</tbody>
             </table>
             </form>
             <p />
               <div className="row">
                 <div className="col s4" align="left">
                   <a href="#!" id="prev" className="waves-effect waves-teal btn-flat" style={divStyle} onClick={this.handlePage}>Zur&uuml;ck</a>
                 </div>
                 <div className="col s4" align="center">
                   <a className={this.getButtonClass()} id="next" href="#!" onClick={this.handlePage}>Weiter</a>
                 </div>
               </div>
               </div>
            )
      }
    }
    
 /* #### Funktion f�r die Eingabe der St�ckzahlen je Palette #### */ 
   class QuantitySelector extends Component {
      constructor(props) {
        super(props);
        this.handlePage = this.handlePage.bind(this);
        /* this.handleButton = this.handleButton.bind(this); */
        this.handleNr = this.handleNr.bind(this); 
        this.state = {
        track: []
        }; 
      }

      componentDidMount() {
        let x = [];
        this.props.orders.forEach((d) => {
            let temp = {};
            if ( (d.marked === true) && (d.pallets > 0) ) {
                let i = 1;
                for (i = 1; i <= d.pallets; i++) {
                    temp = {};
                    temp.pid = d.id + "_" + i;
                    temp.pallet_quantity = 0;
                    temp.id = d.id;
                    temp.ean = d.ean;
                    temp.id_type = d.id_type;
                    temp.po = d.po;
                    if (d.pallets === 1) {
                      temp.pallet_quantity = d.open_quantity;
                    }
                    x.push(temp)
                }
            }
        });
        this.setState({track: x});
      }

      getQuantity(e) {
        let quantity = 0;
        this.state.track.forEach((d) => {       
            if (d.pid === e) {
                quantity = d.pallet_quantity;
        }});
        return quantity;
      }
      
      getItemTotal(e) {
        let total = 0;
        this.state.track.forEach((d) => {       
            if (d.id === e) {
                total = total + Number(d.pallet_quantity);
        }});
        return total;
      }
      
      getErrorMessages() {
        let message = [];
        this.props.orders.forEach((d) => {
            if (this.getItemTotal(d.id)> d.open_quantity) {
                message.push(<p className="error" key="totalexceed">Keine Liefermenge eines Artikels darf die bestellte Menge &uuml;bersteigen.</p>);
            }});
        var pallet_error = false;
        this.state.track.forEach((d) => {
            if (d.pallet_quantity < 1) { pallet_error = true }
        })
        if (pallet_error === true) {
            message.push(<p className="error" key="noquantity">Bitte geben Sie f&uuml;r jede Palette eine Liefermenge an um fortzufahren.</p>);
        }
        return message
      }
      
      handlePage(e) {
         let handover = {};
         handover.shipment = [];
         if (e.target.id === "prev") {
            handover.newpage = 2;
         } else if (e.target.id === "next") {
            handover.newpage = 4; 
         }
         this.state.track.forEach((d) =>
                                  {
                                    let shipitem = {};
                                    if ( (Number.isInteger(d.pallet_quantity) === true) && (d.pallet_quantity > 0) && (d.pallet_quantity < 9999) ) {
                                        shipitem.pid = d.pid;
                                        shipitem.pallet_quantity = d.pallet_quantity;
                                        shipitem.ean = d.ean;
                                        shipitem.id = d.id;
                                        shipitem.id_type = d.id_type;
                                        shipitem.po = d.po;
                                        handover.shipment.push(shipitem);
                                    }
                                    });        
         this.props.onPageChange(handover);  
      }

      handleNr(id, e) {
        this.setState({ track: this.state.track.map((d) =>
                                                   {
                                                    if (d.pid === id) {
                                                        if ( ((e.target.value >= 0) && (e.target.value <= 9999) ) || (e.target.value === "") ) {                                                            
                                                          if (e.target.value === "") {
                                                            console.log("Empty string.");
                                                            d.pallet_quantity = "";
                                                          } else {
                                                            d.pallet_quantity = Number(e.target.value);
                                                          }
                                                        }
                                                    }
                                                   return d
                                                   }
                                                   
                                                   )});
      }
      
      getButtonClass(props) {
        var classname = "";
        let error = this.getErrorMessages();
        //console.log("Error: ", error);
        if (error.length !== 0) {
            classname = "waves-effect waves-light btn-large disabled";
        } else {
            classname = "waves-effect waves-light btn-large";
        }
        return classname
      }
      
      writeTable() {
         var tablecontent = this.props.orders.map((d, i) =>
                                                                     {  let ret;
                                                                        if ( (d.marked === true) && (d.pallets > 0) ) { 
                                                                           let children = []
                                                                           for (let i = 1; i <= d.pallets; i++) {
                                                                              let linekey = "";
                                                                              linekey = d.id + "_" + i;
                                                                              children.push(
                                                                                  <tr key={linekey}>
                                                                                    <td>Palette {i} von {d.pallets}:</td>
                                                                                    <td><input type="text" step="1" max="9999" maxLength="4" name={i} onChange={(e) => this.handleNr(linekey, e)} value={this.getQuantity(linekey)} className="qty2" /></td>
                                                                                    <td width="58.4%" colSpan="4">Exemplare</td>
                                                                                  </tr>                                                                                
                                                                              )
                                                                           }
                                                                           
                                                                           ret=
                                                                             <div className="section" key={d.id}>
                                                                               <table className="striped">
                                                                                 <thead>
                                                                                 <tr>
                                                                                   <th width="33.3%"><b>{d.item_name}<br />ISBN {d.ean}</b></th>
                                                                                   <th colSpan="4"><b>PO#: {d.po}<br />Offene Bestellmenge: {d.open_quantity}</b></th>
                                                                                   <th width="33.3%"><b><br />Liefermenge gesamt: {this.getItemTotal(d.id)}</b></th>
                                                                                 </tr>
                                                                                 </thead>
                                                                                 <tbody>{children}</tbody></table></div>;

                                                                        }
                                                                     return ret   
                                                                     }                                                                            
                                                     );            
      return tablecontent;  
      }
      
      render() {
          return(
            <div className="container">
              <p /><p />
              <h4>St&uuml;ckzahlen je Palette erfassen</h4>
              Bitte geben Sie f&uuml;r jede Palette die exakte Anzahl der auf der Palette lagernden Exemplare an.<br />
              Teillieferungen sind m&ouml;glich. Es kann jedoch nicht mehr als die noch offene Bestellmenge angeliefert werden.<p /><p />
              <form action="#">               
                {this.writeTable()} 
              </form>
              {this.getErrorMessages()}
              <p />
              <div className="row valign-wrapper" align="left">
                 <div className="col s4" align="left">
                   <a href="#!" id="prev" className="waves-effect waves-teal btn-flat" onClick={this.handlePage}>Zur&uuml;ck</a>
                 </div>
                 <div className="col s4" align="center">
                   <a className={this.getButtonClass()} id="next" href="#!" onClick={this.handlePage}>Weiter</a>
                 </div>
                 <div className="col s4"></div>
              </div>
            </div>
            
          )
      }
   }

 /* #### Funktion f�r die Eingabe der Versanddaten #### */ 
   class DateSelector extends Component {
      constructor(props) {
        super(props);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
        }; 
      }
      
      addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy;
      }
      
      componentDidMount() {
        var elems = document.querySelectorAll(".datepicker");
        var today = new Date();
        var maxdate = this.addDays(today, 180);
        var context = this;
        var years = [today.getFullYear(), maxdate.getFullYear()];
        Materialize.Datepicker.init(elems, {
            autoClose: true,
            format: "dd. mmmm yyyy",
            firstDay: 1,
            yearRange: years,
            minDate: today,
            maxDate: maxdate,
            onSelect: function(date) {
                if (this.el.id === "shipout_date") {
                  context.setState({ shipout_date: date});  
                } else if (this.el.id === "shiprec_date") {
                  context.setState({ shiprec_date: date});    
                }
                
            },
            i18n: {
                cancel: 'Abbrechen',
                clear: 'L�schen',
                done: 'OK',
                months: [
                    'Januar',
                    'Februar',
                    'M' + unescape("%E4") + 'rz',
                    'April',
                    'Mai',
                    'Juni',
                    'Juli',
                    'August',
                    'September',
                    'Oktober',
                    'November',
                    'Dezember'
                ],
                monthsShort: [
                    'Jan',
                    'Feb',
                    'Mrz',
                    'Apr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Okt',
                    'Nov',
                    'Dez'
                ],
                weekdays: [
                    'Sonntag',
                    'Montag',
                    'Dienstag',
                    'Mittwoch',
                    'Donnerstag',
                    'Freitag',
                    'Samstag'
                ],
                weekdaysShort: [
                    'So',
                    'Mo',
                    'Di',
                    'Mi',
                    'Do',
                    'Fr',
                    'Sa'
                ],
                weekdaysAbbrev: [
                    'S',
                    'M',
                    'D',
                    'M',
                    'D',
                    'F',
                    'S'
                ]
            }
            
            });
        for (var a of elems) {
            var instance = Materialize.Datepicker.getInstance(a);
            if (a.id === "shipout_date") {
                instance.setDate(today);
            }
        }
      
        var destination = "";
        var vendor = "";
        this.props.orders.forEach((d) => { 
                              if (d.marked === true) {
                                destination = d.destination;
                                vendor = d.vendor;
                              }                     
        });
        this.setState({ destination: destination,
                        vendor : vendor  });
      
      }

      formatDateToString(date){
        // 01, 02, 03, ... 29, 30, 31
        var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
        // 01, 02, 03, ... 10, 11, 12
        var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
        // 1970, 1971, ... 2015, 2016, ...
        var yyyy = date.getFullYear();

        // create the format you want
        return (yyyy.toString() + MM.toString() + dd.toString() );
      }
      
      handlePage(e) {
         let handover = {};

         if (e.target.id === "prev") {
            handover.newpage = 3;
         } else if (e.target.id === "next") {
            handover.destination = this.state.destination;
            handover.vendor = this.state.vendor;
            handover.shipout_date = this.formatDateToString(this.state.shipout_date);
            handover.shiprec_date = this.formatDateToString(this.state.shiprec_date);
            handover.newpage = 5; //change me! 
         } /*
         this.state.track.forEach((d) =>
                                  {
                                    let shipitem = {};
                                    if ( (Number.isInteger(d.pallet_quantity) === true) && (d.pallet_quantity > 0) && (d.pallet_quantity < 9999) ) {
                                        shipitem.pid = d.pid;
                                        shipitem.pallet_quantity = d.pallet_quantity;
                                        shipitem.ean = d.ean;
                                        shipitem.id = d.id;
                                        shipitem.id_type = d.id_type;
                                        shipitem.po = d.po;
                                        handover.shipment.push(shipitem);
                                    }
                                    });      */  
         this.props.onPageChange(handover);  
      }
      
      handleClick(e) {
         //console.log(e.target.id);
         if (document.getElementById(e.target.id)) {
            let tempid = e.target.id + '_date';
            document.getElementById(tempid).click();
         }
      }
      
      getErrorMessages() {
        let message = [];
        var timeerr = 0;
        if (typeof(this.state.shipout_date) === 'undefined') {
            message.push(<p className="error" key="noshipout">Bitte geben Sie ein g&uuml;ltiges Warenausgangsdatum ein.</p>);
            timeerr = 1;
        } else {
            if (isNaN(this.state.shipout_date.getTime())) {
                message.push(<p className="error" key="shipouterr">Das erfasste Warenausgangsdatum ist kein g&uuml;ltiges Datum.</p>);
                timeerr = 1;            
            }
        }
        
        if (typeof(this.state.shiprec_date) === 'undefined') {
            message.push(<p className="error" key="noshiprec">Bitte geben Sie ein erwartetes Anlieferdatum ein.</p>);
            timeerr = 1;
        } else {
            if (isNaN(this.state.shiprec_date.getTime())) {
                message.push(<p className="error" key="shiprecerr">Das erfasste Anlieferdatum ist kein g&uuml;ltiges Datum.</p>);
                timeerr = 1;
            }
        }
        
        if ((timeerr === 0) && (this.state.shipout_date > this.state.shiprec_date)) {
            message.push(<p className="error" key="dateseq">Das Datum der Anlieferung darf nicht vor dem Datum des Warenausgangs liegen.</p>);
        }
        return message
      }

            
      getTotals() {
        var handover = {};
        handover.pallets = this.props.items.length;
        handover.quantity = 0;
        handover.destination = "";
        this.props.items.forEach((d) => { 
                              handover.quantity = handover.quantity + d.pallet_quantity;   
        });

        
       return(handover);
      }
      
      
      getButtonClass(props) {
        var classname = "";
        let error = this.getErrorMessages();
        //console.log("Error: ", error);
        if (error.length !== 0) {
            classname = "waves-effect waves-light btn-large disabled";
        } else {
            classname = "waves-effect waves-light btn-large";
        }
        return classname
      }
      
      render() {
        var today = new Date();
        var dateoptions = { day: '2-digit', month: 'long', year: 'numeric'};
        var totals = this.getTotals();
        
        return(
            <div className="container">
            <p /><p />
            <h4>Liefertermin erfassen</h4>
            Bitte geben Sie das Versanddatum sowie das erwartete Datum der Anlieferung an.<p /><br />
            <b>Datum des Warenausgangs:</b><p />
            <div className="valign-wrapper">
            <i className="small material-icons" id="shipout" onClick={this.handleClick}>date_range</i>&nbsp;&nbsp;&nbsp;<input type="text" id="shipout_date" className="datepicker" defaultValue={today.toLocaleDateString("de-DE", dateoptions)} style={{width: "30%"}} />
            </div>
            <p />
            <b>Erwartetes Datum der Anlieferung:</b><p />
            <div className="valign-wrapper">
            <i className="small material-icons" id="shiprec" onClick={this.handleClick}>date_range</i>&nbsp;&nbsp;&nbsp;<input type="text" id="shiprec_date" className="datepicker" style={{width: "30%"}} />
            </div>
            <br />
            {this.getErrorMessages()}
            <br /><br />
            Ihre vorbereitete Lieferung an das Versandzentrum <b>{this.state.destination}</b> umfasst <b>{totals.pallets} Paletten</b> mit insgesamt <b>{totals.quantity} Einheiten</b>.<br /><br />
            Bitte pr&uuml;fen Sie diese Angaben auf Korrektheit. Nach Klick auf "Jetzt absenden" werden die Palettenetiketten erstellt und die verbindliche Lieferank&uuml;ndigung an Amazon &uuml;bermittelt.<br /><p />
            <br /><br />
            <div className="row valign-wrapper" align="left">
                 <div className="col s4" align="left">
                   <a href="#!" id="prev" className="waves-effect waves-teal btn-flat" onClick={this.handlePage}>Zur&uuml;ck</a>
                 </div>
                 <div className="col s4" align="center">
                   <a className={this.getButtonClass()} id="next" href="#!" onClick={this.handlePage}><i className="material-icons left">send</i>Jetzt absenden</a>
                 </div>
                 <div className="col s4"></div>
            </div>
            </div>
        )
        
      }
   }

 /* #### Funktion f�r die �bermittlung der Versanddaten an den Server und Verarbeitung des Feedbacks #### */ 
   class SubmissionPage extends Component {
      constructor(props) {
        super(props);
        //this.handlePage = this.handlePage.bind(this);
        this.state = {
            track : {},
            usermail : ""
        }; 
      }

      componentDidMount() {
        var subobj = this.props.shipment;
        subobj.payload = [];
        var tempitem = {};
        this.props.items.forEach((d) => {
            tempitem = {};
            tempitem.itemid = d.ean;
            tempitem.itemid_type = d.id_type;
            tempitem.po = d.po;
            tempitem.quantity = d.pallet_quantity;
            subobj.payload.push(tempitem);
        });
        var parcel = JSON.stringify(subobj);
        console.log("Subobj: ", subobj);
        console.log("JSON: ", parcel);
        
        let apiName="Amazon Get Orders";
        let path="/prod/shipments";
        let myInit = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: subobj
        }
        /*
        fetch('https://l4933dm05d.execute-api.eu-west-1.amazonaws.com/prod/shipments', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: parcel    
        }) */
        API.post(apiName, path, myInit)
        //.then(res => res.json())
        .then((data) => {
            console.log(data);
            this.setState({ track: data });
            if (data.hasOwnProperty("filename")) {
                console.log("Filename: ", data.filename);
              }})
        .catch(console.log);
      
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            //console.log(user);
            this.setState({ usermail: user.attributes.email })
                })
        .catch(err => console.log(err));
      
      }
      
      evaluateResponse() {
        if (this.state.track.hasOwnProperty("filename")) {
            let filename = '../labels/' + this.state.track.filename + '.pdf';
            return (
                <div>
                <p />
                <h4>Ihr Lieferavis wurde erfolgreich &uuml;bermittelt.</h4><br /><p />
                Die Sendungsnummer (BOL/ASN) lautet: <b>{this.state.track.shipment_id}</b>. Bitte geben Sie diese auf den Lieferpapieren an.<br /><p /><p />
                Palettenetiketten f&uuml;r diese Lieferung wurden an Ihre hinterlegte E-Mail-Adresse <b>{this.state.usermail}</b> versendet.<br />
                Sie k&ouml;nnen die Etiketten optional auch hier herunterladen:<br /><p /><br />
                <center><a href={filename} target="_blank" rel="noopener noreferrer" className="waves-effect waves-light btn-large"><i className="material-icons right">cloud_download</i>Download Etiketten</a>
                <br /><p />
                <a href="./">Weitere Lieferung anlegen</a>
                </center>
                </div>
            );
        } else if (this.state.track.hasOwnProperty("errormessage")) {
            return (
                <div>
                <p />
                <span className="red-text text-darken-3"><b>Das Lieferavis konnte nicht &uuml;bermittelt werden.</b></span><br /><p />
                Fehlermeldung: {this.state.track.errormessage}<p />
                Bitte informieren Sie Ihren Ansprechpartner bei Amazon unter Angabe der Fehlermeldung.
                </div>
            );
        } else {
            return (
                <div>
                <p />
                Bitte warten Sie einen Moment...
                </div>
            );
        }
        
      }
      
      render() {
        return(
            <div className="container">
                {this.evaluateResponse()}
            </div>
            
            
        )
      }
      
   }

export default withAuthenticator(App, true, [
                                             <Greetings inGreeting=' ' />,
                                             <SignIn/>,
                                             <ConfirmSignIn/>,
                                             <VerifyContact/>,
                                             <ForgotPassword/>,
                                             <RequireNewPassword/>
                                             ]
                                 );
